import {
    AbstractControl,
    FormControl,
    FormGroupDirective,
    NgForm,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export class CustomErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}
export class DateValidators {
    // DateValidators.dateLessThan('loadDate', 'deliveryDate', { 'loaddate': true }),
    static dateLessThan(
        dateField1: string,
        dateField2: string,
        validatorField: { [key: string]: boolean }
    ): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            const date1 = c.get(dateField1).value;
            const date2 = c.get(dateField2).value;
            if (date1 != null && date2 != null && date1 > date2) {
                return validatorField;
            }
            return null;
        };
    }
}

export class TextValidators {
    static cannotContainSpace(control: AbstractControl): ValidationErrors | null {
        if ((control.value as string).indexOf(' ') >= 0) {
            return { cannotContainSpace: true };
        }
        return null;
    }
}
